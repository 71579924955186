<template>
  <div class="campaign-channel-page">
    <TrendingVue :title="'Trending'" :mode="mode" :filterResult="filter" />
    <!-- <CategoryAndSentiment :filterResult="filterResult" /> -->
    <!-- <TrendingOverview :mode="mode" :filterResult="filterResult" /> -->
    <TopPostByMode :mode="mode" :filterResult="filter" />
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { useStore } from 'vuex';

import TrendingVue from '../../Overview/TopicTrending/Trending.vue';
// import TrendingOverview from './TrendingOverview.vue';
import TopPostByMode from './TopPostByMode.vue';
// import CategoryAndSentiment from './CategoryAndSentiment.vue';

export default {
  name: 'CampaignTopicTrending',
  components: {
    TrendingVue,
    // TrendingOverview,
    TopPostByMode,
    // CategoryAndSentiment,
  },
  props: {
    mode: String,
    filterResult: Object,
    campaign: Object,
  },
  setup(props) {
    const store = useStore();
    const { campaign, filterResult: filter } = toRefs(props);
    const { state } = store;
    const categoryData = computed(() => state.config.categoryData);
    let categoryLevel = ref(campaign.value.level);

    const selectedCategoryLevel = computed(() => {
      if (campaign.value) {
        return categoryLevel.value;
      } else {
        return 0;
      }
    });

    const categoryByLevel = computed(() => {
      const result = {
        id: categoryData.value[selectedCategoryLevel.value].id,
        level: categoryData.value[selectedCategoryLevel.value].level,
        name: categoryData.value[selectedCategoryLevel.value].name,
        categories: [],
      };
      for (let cat of categoryData.value[selectedCategoryLevel.value].categories) {
        if (campaign.value && cat.category === campaign.value.name) {
          result.categories.push(cat);
        }
      }
      return result;
    });

    return {
      categoryByLevel,
      categoryData,
      selectedCategoryLevel,
      filter,
      //   SentimentComparison,
      // CategoryAndSentiment,
    };
  },
};
</script>

<style lang="scss" scoped>
.social-movement-card,
.breadcrumb-menu {
  // Chart
  .chart-column-card {
    .chart-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .arrow-back {
        display: flex;
        align-self: flex-start;
        align-items: center;
        .back-text {
          margin-left: 5px;
        }

        .level-button {
          width: 120px;
        }
        .level-list {
          display: flex;
          // font-size: 12px;
        }
      }

      x-vue-echarts {
        display: flex !important;
        height: 400px !important;
      }
    }
  }
  .category-list-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .category-list-wrapper {
    min-height: 370px;
    max-height: 400px;
    min-width: 120px;
    max-width: 350px;
    // max-width: 220px;
    overflow: auto;
    padding: 25px 20px 0px 0px;
    // padding: 0px 10px 20px 0px;
    @media only screen and (max-width: 766px) {
      padding: 0px;
      min-width: 330px;
      min-height: 180px;
      align-self: center;
    }
    .category-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
      .left-block {
        display: flex;
        align-items: center;
        .category-title {
          .category-name {
            color: #868eae;
          }
        }
      }
      .right-block {
        .right-value {
          color: #868eae;
        }
      }

      .color-muted {
        color: #ccc;
      }
    }
  }

  .paginate-button {
    display: flex;
    justify-content: center;
    min-width: 120px;
    max-width: 350px;
    margin-top: 8px;
    @media only screen and (max-width: 766px) {
      padding: 0px;
      min-width: 330px;
      align-self: center;
    }
  }

  .arrow-back {
    display: flex;
    align-self: flex-start;
    align-items: center;
    .back-text {
      margin-left: 5px;
    }

    .level-button {
      width: 120px;
    }
    .level-list {
      display: flex;
    }
  }

  // Table
  .table-wrapper {
    .comparison-table-scroll {
      //   width: 400px;
      overflow-x: scroll;
      border-left: 1px solid #f1f2f6;
      border-right: 1px solid #f1f2f6;
      //   margin-bottom: 10px;
      //   border: solid black 1px;
      table,
      th,
      td {
        border-collapse: collapse;
        margin: 0;
        white-space: nowrap;
        padding: 16px;
        border-top: 1px solid #f1f2f6;
        // border: 1px solid #f1f2f6;
      }

      table th:first-child {
        position: sticky;
        left: 0;
        background-color: white;
        width: 40px;
      }

      table td:first-child {
        position: sticky;
        left: 0;
        background-color: #f8f9fb;
        width: 40px;
      }

      .comparison-table {
        //   table-layout: fixed;
        width: 100%;

        .column-title {
          text-transform: capitalize;
          padding: 16px;
          z-index: 1;
        }

        .column-title-brand {
          text-align: center;
          text-transform: capitalize;

          .text-brand {
            font-weight: 500;
            color: #272b41;
          }
        }

        .divide-row-title {
          // z-index: 2;
          font-weight: 500;
          line-height: 17px;
          color: #272b41;
          //   border-right: 1px solid transparent;
        }

        .row-title {
          // z-index: 2;
          position: sticky;
          left: 0;
          font-weight: 400;
          line-height: 18px;
          color: #5a5f7d;
          background: #ffffff;
          i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          .metric-name {
            margin-left: 25px;
          }
        }
        .row-title-blank {
          background: #f8f9fb;
          border: none;
        }

        .table-content {
          // color: #5a5f7d;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}
.campaign-channel-page {
  margin-bottom: 10px;
  width: 100%;
  .campaign-channel-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-bottom: 24px;
    .social-movement-filter-mode {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .fa-stack {
        display: inline-block;
        height: 14px;
        line-height: 13px;
        position: relative;
        vertical-align: middle;
        width: 14px;
        font-size: 14px;
        .fa-circle {
          left: 0;
          position: absolute;
          text-align: center;
          width: 100%;
          font-size: 14px;
        }
        .icon-social {
          left: 0;
          position: absolute;
          text-align: center;
          width: 100%;
          font-size: 8px;
          color: #fff;
          padding: 3px 0;
        }
        .icon-twitter {
          color: #1da1f2;
        }
        .icon-instagram {
          color: #dd2a7b;
        }
        .icon-youtube {
          color: #e32212;
        }
        .icon-comments {
          color: #7459c8;
        }
        .icon-globe {
          color: #252525;
        }
      }
      .icon-facebook {
        color: #1977f2;
      }
      .ant-radio-button-wrapper-checked {
        .icon-facebook {
          color: #fff;
        }
        .fa-stack {
          .icon-twitter,
          .icon-instagram,
          .icon-youtube,
          .icon-comments,
          .icon-globe {
            color: #fff;
          }
          .icon-social {
            color: #1890ff;
          }
        }
      }
      .source-img {
        height: 14px;
        width: 14px;
      }
      .ant-radio-button-wrapper {
        font-weight: 500;
        font-size: 14px;
        color: #434870;
      }
      .social-movement-data-aspect {
        margin-right: 14px;
        font-size: 14px;
        color: #5a5f7d;
      }
    }
  }
}
</style>
<style scoped>
:deep(.icon-website .feather-globe) {
  width: 8px;
  height: 8px;
}
</style>
