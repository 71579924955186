<template>
  <OverallVue :mode="mode" :filterResult="filterResult" :campaignLevel="campaignLevel"></OverallVue>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import OverallVue from '../../../components/Contents/Overall.vue';

export default {
  name: 'PerformanceOverall',
  components: {
    OverallVue,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup() {
    const store = useStore();
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);

    return {
      campaignLevel,
    };
  },
};
</script>
