<template>
  <div class="campaign-channel-page">
    <div class="campaign-channel-column">
      <SourceButton :selectedSource="selectedDataAspect" @handleModeClick="handleModeClick"></SourceButton>
    </div>
    <ChannelTrending :source="selectedDataAspect" :filterResult="filter" />
    <ChannelPost :source="selectedDataAspect" :filterResult="filter" />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, computed, toRefs, watch, onMounted } from 'vue';

import ChannelTrending from './ChannelTrending.vue';
import ChannelPost from './ChannelPost.vue';
import SourceButton from '@/components/Button/SourceButton.vue';
export default {
  name: 'CampaignChannel',
  components: {
    ChannelTrending,
    ChannelPost,
    SourceButton,
  },
  props: {
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    const { filterResult: filter } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let selectedDataAspect = ref('facebook');

    const handleModeClick = (source) => {
      selectedDataAspect.value = source;
    };

    const init = () => {
      filter.value.source = [];
      filter.value.source.push(selectedDataAspect.value);
    };

    watch(
      () => selectedDataAspect.value,
      () => {
        init();
      }
    );

    watch(
      () => filter.value,
      () => {
        init();
      }
    );

    onMounted(() => {
      init();
    });

    return {
      selectedDataAspect,
      isMobileScreen,
      handleModeClick,
      filter,
    };
  },
};
</script>

<style lang="scss" scoped>
.ant-radio-group {
  display: flex;
  width: 100%;
  overflow: auto;
}
label.ant-radio-button-wrapper {
  flex: 0 0 auto;
}
.ant-radio-group {
  display: flex;
}
.campaign-channel-page {
  // height: 100%;
  margin-bottom: 10px;
  width: 100%;
  .campaign-channel-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-bottom: 24px;
  }
}
</style>
