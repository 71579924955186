<template>
  <a-card
    class="campaign-channel-trending no-y-padding"
    :title="`${
      filterResult.source[0] === 'twitter'
        ? 'X (Twitter)'
        : filterResult.source[0]
    } Overview`"
  >
    <template #extra>
      <div v-if="!isMobileScreen" class="chart-type">
        <a-button
          :type="chartType === 'line' ? 'primary' : 'ghost'"
          class="circular btn-line"
          :class="{ 'button-primary-light': chartType === 'line' }"
          style="width: 42px"
          @click="chartType = 'line'"
        >
          <FeatherIcon type="trending-up" size="18" />
        </a-button>
        <a-button
          :type="chartType === 'column' ? 'primary' : 'ghost'"
          class="circular"
          :class="{ 'button-primary-light': chartType === 'column' }"
          style="width: 42px"
          @click="chartType = 'column'"
        >
          <FeatherIcon type="bar-chart" size="18" />
        </a-button>
      </div>
      <div v-else class="chart-type">
        <a-dropdown
          placement="bottomRight"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
          <template #overlay>
            <a-menu id="mode-menu-mobile">
              <a-menu-item-group title="Graph:">
                <a-menu-item style="background: #fff">
                  <a-button
                    :type="chartType === 'line' ? 'primary' : 'ghost'"
                    class="circular btn-line"
                    :class="{ 'button-primary-light': chartType === 'line' }"
                    style="width: 42px"
                    @click="chartType = 'line'"
                  >
                    <FeatherIcon type="trending-up" size="18" />
                  </a-button>
                  <a-button
                    :type="chartType === 'column' ? 'primary' : 'ghost'"
                    class="circular"
                    :class="{ 'button-primary-light': chartType === 'column' }"
                    style="width: 42px"
                    @click="chartType = 'column'"
                  >
                    <FeatherIcon type="bar-chart" size="18" /> </a-button
                ></a-menu-item>
              </a-menu-item-group>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </template>
    <div class="card">
      <a-row v-if="filterResult.source.length > 0" :gutter="[25, 25]">
        <a-col v-if="source === 'twitter'" class="pd-unset">
          <TwitterOverview :loading="loading" :overviewData="overviewData" />
        </a-col>
        <a-col v-else class="pd-unset">
          <FacebookOverview :loading="loading" :overviewData="overviewData" />
        </a-col>
      </a-row>
      <a-row v-if="!loading && seriesData.length > 0">
        <a-col :span="24" :offset="0">
          <TrendingChart
            class="chart"
            :chart-type="chartType"
            :series="seriesData"
          ></TrendingChart>
        </a-col>
      </a-row>
      <div v-if="loading" class="load-wrapper">
        <a-skeleton :loading="loading" active />
      </div>
      <div v-if="!loading && seriesData.length <= 0" class="load-wrapper">
        <NoDataFound></NoDataFound>
      </div>
    </div>
  </a-card>
</template>

<script>
import numeral from 'numeral';
import TrendingChart from '@/components/Chart/TrendingChart';
import api from '@/services/api';
import { ref, computed, toRefs, watch, reactive } from 'vue';
import FacebookOverview from '@/components/Chart/FacebookOverview.vue';
import TwitterOverview from '@/components/Chart/TwitterOverview.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';
import { useStore } from 'vuex';
export default {
  name: 'CampaignChannelTrending',
  components: {
    TrendingChart,
    FacebookOverview,
    TwitterOverview,
    NoDataFound,
  },
  props: {
    filterResult: Object,
    source: String,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    let { filterResult, source } = toRefs(props);
    const isMobileScreen = computed(() => state.isMobileScreen);
    const campaignLevel = computed(
      () => state.account.campaignMenuData.categoryLevel
    );
    let loading = ref(false);
    let error = ref(false);
    let seriesData = ref([]);
    const chartType = ref('line');
    const currentGraphCriteria = ref({});
    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);
      // console.log('CLIKC', type, time, title, payload);
      let f = {
        payload: {
          title: `${payload.title} ${title}`,
        },
        criteria: {
          ...filterResult.value,
          time,
          ...payload.criteria,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (campaignLevel.value > 0) {
        f.criteria['subCategory'] = filterResult.value.subCategory;
      } else {
        f.criteria['category'] = payload.criteria.category;
        f.criteria['subCategory'] = [];
      }
      dispatch('message/showMessageModal', f);
    };
    let overviewData = reactive({
      engagement: {
        value: 0,
        percentChange: 0,
      },
      mention: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      view: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      videoView: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      pageView: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      reaction: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      comment: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      share: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      retweet: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
      quote: {
        value: 0,
        percentChange: 0,
        formatted: '0',
      },
    });
    const loadChartData = async (rawFilter) => {
      loading.value = true;
      error.value = false;
      if (rawFilter && rawFilter.source) {
        const { graphCriteria, granularity } = chartHelper.modifyGranularity(
          {},
          rawFilter
        );
        currentGraphCriteria.value = graphCriteria;
        const apiFilter = {
          ...rawFilter,
          ...graphCriteria,
        };
        const result = await api.getOverviewHistory(apiFilter).catch(() => {
          console.error('History api call error', result);
          error.value = true;
        });
        if (
          result.message &&
          result.message.graphData &&
          Object.keys(result.message.graphData).length !== 0
        ) {
          const data = result.message.graphData;
          let dataSource = [];
          let arrangeDataSource = [];
          let colors = ['#20C997', '#FF87B7', '#5F63F2'];
          let countComments = 0;
          let countLike = 0;
          let countShare = 0;
          Object.keys(data).forEach(function (key, index) {
            dataSource.push({
              name: key,
              color: colors[index],
              data: Object.values(data[key].data),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.options, { title: 'Messages' }),
                },
              },
              custom: {
                granularity,
              },
            });

            Object.values(data[key].data).forEach(function (value) {
              if (key === 'Comment & Reply') {
                if (value.y > 0) countComments += 1;
              }
              if (key === 'Like & Reaction') {
                if (value.y > 0) countLike += 1;
              }
              if (key === 'Share') {
                if (value.y > 0) countShare += 1;
              }
            });
          });

          if (countComments === 0 && countLike === 0 && countShare === 0) {
            arrangeDataSource = [];
          } else {
            arrangeDataSource.push(dataSource[1], dataSource[0], dataSource[2]);
          }

          seriesData.value = arrangeDataSource;
          chartType.value = 'line';
        }
      }
      loading.value = false;
    };

    const loadOverviewData = async (apiFilter) => {
      const qs = reactive({});
      const result = await api
        .getSocialStatSummaryData(apiFilter, qs)
        .catch(() => {
          // console.error('Social stat summary api call error', result);
          error.value = true;
        });
      if (result.message != null) {
        if (result.message.engagement != null) {
          Object.assign(overviewData.engagement, result.message.engagement);
        }
        if (result.message.mention != null) {
          Object.assign(overviewData.mention, result.message.mention);
          overviewData.mention.formatted = numeral(
            overviewData.mention.value
          ).format('0,0');
        }
        if (result.message.view != null) {
          Object.assign(overviewData.view, result.message.view);
          overviewData.view.formatted = numeral(overviewData.view.value).format(
            '0,0'
          );
        }
        if (result.message.videoView != null) {
          Object.assign(overviewData.videoView, result.message.videoView);
          overviewData.videoView.formatted = numeral(
            overviewData.videoView.value
          ).format('0,0');
        }
        if (result.message.pageView != null) {
          Object.assign(overviewData.pageView, result.message.pageView);
          overviewData.pageView.formatted = numeral(
            overviewData.pageView.value
          ).format('0,0');
        }
        if (result.message.reaction != null) {
          Object.assign(overviewData.reaction, result.message.reaction);
          overviewData.reaction.formatted = numeral(
            overviewData.reaction.value
          ).format('0,0');
        }
        if (result.message.comment != null) {
          Object.assign(overviewData.comment, result.message.comment);
          overviewData.comment.formatted = numeral(
            overviewData.comment.value
          ).format('0,0');
        }
        if (result.message.share != null) {
          Object.assign(overviewData.share, result.message.share);
          overviewData.share.formatted = numeral(
            overviewData.share.value
          ).format('0,0');
        }
        if (result.message.retweet != null) {
          Object.assign(overviewData.retweet, result.message.retweet);
          overviewData.retweet.formatted = numeral(
            overviewData.retweet.value
          ).format('0,0');
        }
        if (result.message.quote != null) {
          Object.assign(overviewData.quote, result.message.quote);
          overviewData.quote.formatted = numeral(
            overviewData.quote.value
          ).format('0,0');
        }
      }
    };

    const init = (fs) => {
      loadOverviewData(fs);
      loadChartData(fs);
    };

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      }
    );

    watch(
      () => source.value,
      () => {
        init(filterResult.value);
      }
    );

    return {
      seriesData,
      chartType,
      loading,
      isMobileScreen,
      overviewData,
    };
  },
};
</script>

<style lang="scss" scoped>
.ant-dropdown-menu {
  min-width: unset;
}
.ant-btn.ant-btn-primary {
  background: rgba(51, 113, 255, 0.1);
  color: rgba(51, 113, 255);
}
.campaign-channel-trending {
  text-transform: capitalize;
}
.trending-history-card .ant-card-body {
  padding: 0 25px !important;
}
.card {
  min-height: 490px;
}

.data-representation-selector-wrapper {
  display: flex;
  justify-content: flex-end;
}

.chart {
  margin-top: 25px;
}
.chart-type {
  width: fit-content;
  margin-left: auto;
  padding: 0;
  line-height: 1.4;
}

.accumulation-wrapper {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 16px;
  column-gap: 16px;
  .acc-toggle {
    margin-top: 0;
    justify-content: center;
  }

  @media only screen and (max-width: 1330px) and (min-width: 992px) {
    .acc-toggle {
      order: 2;
      margin-right: 48px;
    }
    .mode-toggle {
      order: 1;
    }
  }
}

// @media only screen and (max-width: 992px) {
//   .data-representation-selector-wrapper {
//     justify-content: flex-start;
//   }
//   .acc-toggle {
//     order: 1;
//   }
//   .mode-toggle {
//     order: 2;
//   }
// }

.hidden {
  display: none;
}

.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 385px;
}

.pd-unset {
  padding-left: unset !important;
  padding-right: unset !important;
}

@media only screen and (max-width: 768px) {
  .ant-btn.ant-btn-ghost {
    background: rgba(244, 245, 247, 0.6);
    border-radius: 36px;
    color: #9299b8;
  }
  .btn-line {
    margin-right: 5px;
  }
}
</style>
