<template>
  <div class="campaign-performance-page">
    <PerformanceOverall :mode="mode" :filterResult="filter" />
    <PerformanceTimeline :campaign="campaign" :mode="mode" :filterResult="filter" />
    <PerformanceChannel :mode="mode" :filterResult="filter" />
    <a-row :gutter="25">
      <a-col :lg="12" :xs="24">
        <PerformanceSentiment class="card-height" :mode="mode" :filterResult="filter" />
      </a-col>
      <a-col :lg="12" :xs="24">
        <PerformanceSentimentByChannel class="card-height" :mode="mode" :filterResult="filter" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import PerformanceOverall from './PerformanceOverall.vue';
import PerformanceTimeline from './PerformanceTimeline.vue';
import PerformanceChannel from './PerformanceChannel.vue';
import PerformanceSentimentByChannel from './PerformanceSentimentByChannel.vue';
// import ChannelAndCategory from '@/views/Overview/ChannelAndCategory.vue';
import PerformanceSentiment from './PerformanceSentiment.vue';
export default {
  name: 'CampaignPerformance',
  components: {
    PerformanceOverall,
    PerformanceTimeline,
    PerformanceChannel,
    // ChannelAndCategory,
    PerformanceSentiment,
    PerformanceSentimentByChannel,
  },
  props: {
    mode: String,
    filterResult: Object,
    campaign: Object,
  },
  setup(props) {
    const { campaign, filterResult: filter } = toRefs(props);
    const store = useStore();
    const { state } = store;

    const categoryData = computed(() => state.config.categoryData);
    let categoryLevel = ref(campaign.value.level);

    const selectedCategoryLevel = computed(() => {
      if (campaign.value) {
        return categoryLevel.value;
      } else {
        return 0;
      }
    });

    const categoryByLevel = computed(() => {
      const result = {
        id: categoryData.value[selectedCategoryLevel.value].id,
        level: categoryData.value[selectedCategoryLevel.value].level,
        name: categoryData.value[selectedCategoryLevel.value].name,
        categories: [],
      };
      for (let cat of categoryData.value[selectedCategoryLevel.value].categories) {
        if (campaign.value && cat.category === campaign.value.name) {
          result.categories.push(cat);
        }
      }
      return result;
    });

    return {
      categoryByLevel,
      categoryData,
      selectedCategoryLevel,
      filter,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-performance-page {
  height: 100%;
  width: 100%;
}
.card-height {
  height: 96%;
}
</style>
