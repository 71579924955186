<template>
  <div class="item-table">
    <table v-if="lists.length > 0" class="table" @scroll="onScroll">
      <tbody>
        <template v-for="(item, index) in lists" :key="item.id">
          <tr
            :class="{ expand: expandRowId === index }"
            :rowspan="expandRowId === index ? 2 : 1"
            class="item-row"
            @click="showMessageModal(item.id, item.info.user_name)"
          >
            <td
              class="blue-text text-center middle"
              :class="{
                'gold-text': index == 0,
                'silver-text': index == 1,
                'bronze-text': index == 2,
              }"
            >
              {{ index + 1 }}
            </td>
            <td>
              <div class="d-flex">
                <div class="user-photo-wrap">
                  <img
                    class="user-photo"
                    :src="item.newProfileImage"
                    @error="onImageError($event, item)"
                  />
                </div>
                <div class="user-info">
                  <div class="username">
                    {{ item.info.user_name }}
                  </div>
                  <div class="source-row">
                    <SourceTag :source="item.source" />
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="unit-text">
                <span v-if="sortBy === 'engagement'">Engagement</span>
                <span v-else-if="sortBy === 'count'">Mentions</span>
                <span v-else-if="sortBy === 'like_reaction_count'"
                  >Reactions</span
                >
                <span v-else-if="sortBy === 'comment_count'">Comments</span>
                <span v-else-if="sortBy === 'share_count'">Shares</span>
                <span v-else-if="sortBy === 'view_count'">Views</span>
              </div>
              <div class="value-text blue-text">
                <span v-if="sortBy === 'engagement'">
                  {{ item.totalEngage }}
                </span>
                <span v-else-if="sortBy === 'count'">
                  {{ item.totalMention }}
                </span>
                <span v-else-if="sortBy === 'like_reaction_count'">
                  {{ item.engagement.reaction || 0 }}
                </span>
                <span v-else-if="sortBy === 'comment_count'">
                  {{ item.engagement.comment || 0 }}
                </span>
                <span v-else-if="sortBy === 'share_count'">
                  {{ item.engagement.share || 0 }}
                </span>
                <span v-else-if="sortBy === 'view_count'">
                  {{ item.engagement.view || 0 }}
                </span>
              </div>
            </td>
            <td class="text-center">
              <div class="menu-item">
                <a-dropdown :placement="'bottomRight'" trigger="click">
                  <div class="user-menu-button" @click.stop="">
                    <FeatherIcon type="more-horizontal" size="18" />
                  </div>
                  <template #overlay>
                    <a-menu class="user-influencer-menu">
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="openUserLink(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="external-link" />
                          Open Link
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="copyUserLink(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="copy" />
                          Copy Link
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="showMessageModal(item.id, item.info.user_name)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="eye" />
                          View Message
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="showNetworkGraph(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="share-2" />
                          Network Graph
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
              <div class="expand-item" @click.stop="toggleExpandRow(index)">
                <FeatherIcon v-if="expandRowId === index" type="chevron-up" />
                <FeatherIcon v-else type="chevron-down"></FeatherIcon>
              </div>
            </td>
          </tr>
          <tr v-show="expandRowId === index" class="expand-row">
            <td colspan="4">
              <div class="pl-3 pr-3">
                <div class="total-list">
                  <div class="total-block">
                    <div class="value-row">
                      {{ item.totalEngage }}
                    </div>
                    <div class="unit-row">Engagement</div>
                  </div>
                  <div class="total-block">
                    <div class="value-row">
                      {{ item.totalMention }}
                    </div>
                    <div class="unit-row">Mentioned</div>
                  </div>
                </div>
                <div class="action-list no-bordersmall">
                  <div class="title">Engagement</div>
                  <div
                    v-if="typeof item.engagement.reaction === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'thumbs-up'" size="12" />
                      Reactions
                    </div>
                    <div class="action-value">
                      {{ item.engagement.reaction || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.like === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'thumbs-up'" size="12" />
                      Like
                    </div>
                    <div class="action-value">
                      {{ item.engagement.like || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.comment === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'message-square'" size="12" />
                      Comment
                    </div>
                    <div class="action-value">
                      {{ item.engagement.comment || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.share === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'share-2'" size="12" />
                      Share
                    </div>
                    <div class="action-value">
                      {{ item.engagement.share || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.retweet === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'repeat'" size="12" />
                      Retweet
                    </div>
                    <div class="action-value">
                      {{ item.engagement.retweet || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.quote === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'edit-3'" size="12" />
                      Quote
                    </div>
                    <div class="action-value">
                      {{ item.engagement.quote || 0 }}
                    </div>
                  </div>
                </div>
                <div class="action-list list-border">
                  <div class="title">Mentioned</div>
                  <div
                    v-if="typeof item.mention.post === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon :type="'file-text'" size="12" />
                      Post
                    </div>
                    <div class="action-value">
                      {{ item.mention.post }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.mention.tweet === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <font-awesome-icon :icon="faXTwitter" />
                      Tweet
                    </div>
                    <div class="action-value">
                      {{ item.mention.tweet }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.mention.comment === 'string'"
                    class="action-row"
                  >
                    <div v-if="item.source === 'twitter'" class="action-title">
                      <FeatherIcon :type="'repeat'" size="12" />
                      <span>Retweet</span>
                    </div>
                    <div v-else class="action-title">
                      <FeatherIcon :type="'message-square'" size="12" />
                      <span>Comment</span>
                    </div>
                    <div class="action-value">
                      {{ item.mention.comment || 0 }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-else class="load-wrapper">
      <NoDataFound></NoDataFound>
    </div>
    <!-- <SeeMoreDialog /> -->
    <a-modal
      v-model:visible="isNetworkModalOpen"
      :title="networkData.title"
      :footer="null"
      :on-cancel="onNetworkModalCancel"
      width="838px"
      :z-index="1000"
    >
      <div v-if="networkData.loading" class="loading-wrap">
        <a-spin />
      </div>
      <div
        v-show="!networkData.loading"
        id="influencer-graph"
        ref="infGraph"
      ></div>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, provide, toRefs } from 'vue';
import { useStore } from 'vuex';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import helper from '@/services/helper';
import api from '@/services/api';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SourceTag from '@/components/SourceTag.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
const networkGraphOption = {
  autoResize: true,
  height: '500px',
  width: '100%',
  interaction: {
    hover: true,
    navigationButtons: true,
    keyboard: {
      enabled: true,
      bindToWindow: false,
    },
  },
  edges: {
    smooth: {
      forceDirection: 'none',
      roundness: 0.4,
    },
    scaling: {
      min: 2,
      max: 10,
    },
  },
  nodes: {
    color: {
      highlight: {
        border: '#ff4d4d',
        background: '#ff4d4d',
      },
      hover: {
        border: '#99ffbb',
        background: '#99ffbb',
      },
    },
    scaling: {
      min: 25,
      max: 35,
      label: {
        min: 15,
        max: 18,
      },
    },
  },
  physics: {
    enabled: true,
    forceAtlas2Based: {
      gravitationalConstant: -50,
      centralGravity: 0.01,
      springConstant: 0.1,
      springLength: 80,
      damping: 0.5,
      avoidOverlap: 0.8,
    },
    minVelocity: 2,
  },
};
export default {
  name: 'InfluencerLists',
  components: {
    // SeeMoreDialog,
    SourceTag,
    NoDataFound,
  },
  props: {
    influencerDataList: Array,
    filterResult: Object,
    sortBy: String,
    campaign: Object,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, influencerDataList: lists, campaign } = toRefs(props);
    const limitCount = ref(10);
    const error = ref(false);
    const isNetworkModalOpen = ref(false);
    const infGraph = ref();
    const expandRowId = ref('');
    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };
    const networkData = reactive({
      nodes: new DataSet(),
      edges: new DataSet(),
      loading: false,
      title: '',
      allParent: [],
      networkParent: null,
      edgeLimit: 50,
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      if (campaign.value.name) {
        if (campaign.value.level > 0) {
          const category = filterResult.value.subCategory;
          for (let i in f.criteria['subCategory']) {
            if (f.criteria['subCategory'][i].level == campaign.value.level) {
              f.criteria['subCategory'][i] = category[0];
            }
          }
        } else {
          f.criteria['category'] = filterResult.value.category;
          f.criteria['subCategory'] = [];
        }
      }
      store.dispatch('message/showMessageModal', f);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const htmlTitle = (html) => {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    };

    const setParent = (parent) => {
      networkData.title = `Network graph of "${parent.info.user_name || '-'}"`;
      let actions = parent.actions;
      let reactions = [];
      Object.keys(parent.engagement).forEach((engageKey) => {
        reactions.push({
          key: engageKey,
          value: parent.engagement[engageKey],
        });
      });

      let title =
        '' +
        '<div class="name">' +
        parent.info.user_name +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        helper.numeral(parent.node_score, '0,0', false, null) +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';

      const img = parent.img || parent.newProfileImage;
      networkData.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.info.user_name,
        shape: 'circularImage',
        image: img,
        title: htmlTitle(title),
        value: 2,
      });
    };
    const setChild = async (parent) => {
      const networkArg = {
        ...filterResult.value,
        nodeLimit: 50,
        edgeLimit: networkData.edgeLimit,
      };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(true, parentID, networkArg)
        .catch(() => {
          console.log('ERRR');
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image: childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      networkData.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = networkData.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = networkData.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      networkData.edges.update(childlist.relationship);
      networkData.loading = false;
    };

    const showNetworkGraph = async (parent) => {
      isNetworkModalOpen.value = true;
      networkData.loading = true;
      networkData.allParent = [];
      networkData.networkParent = parent;
      networkData.nodes.clear();
      networkData.edges.clear();
      setParent(parent);
      await setChild(parent);
      // console.log(infGraph.value, networkData.nodes, networkData.edges, Network, networkGraphOption);

      new Network(
        infGraph.value,
        { nodes: networkData.nodes, edges: networkData.edges },
        networkGraphOption
      );
    };
    const onNetworkModalCancel = () => {
      console.log('OHH');
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        window.open(item.info.user_link, '_blank');
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        navigator.clipboard.writeText(item.info.user_link);
      }
    };

    const onImageError = (e, value) => {
      if (value.source === 'facebook') {
        if (value.fallbackImage && e.target.src !== value.fallbackImage) {
          e.target.src = value.fallbackImage;
        } else {
          const img = helper.getDefaultImageProfile(value.source);
          return img;
        }
      } else {
        const img = helper.getDefaultImageProfile(value.source);
        return img;
      }
    };

    provide('showMode', '');

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName(
        'user-influencer-menu'
      );
      if (scrollElement) {
        for (let ele of scrollElement) {
          // ele.remove();
          ele.style.display = 'none';
        }
      }
    };

    return {
      error,
      clickMenu,
      openUserLink,
      onNetworkModalCancel,
      copyUserLink,
      showNetworkGraph,
      onImageError,
      showMessageModal,
      onScroll,
      toggleExpandRow,
      expandRowId,
      limitCount,
      lists,
      infGraph,
      isNetworkModalOpen,
      networkData,
      faXTwitter,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-border {
  border-bottom: 1px solid #f1f2f6;
}
.action-list {
  padding-top: 12px;
  padding-bottom: 4px;
  .title {
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 8px;
    .action-title {
      color: #9299b8;
      font-size: 12px;
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    .action-value {
      color: #5a5f7d;
    }
  }
}

.total-list {
  border: 1px solid #f1f2f6;
  padding: 16px;
  background-color: #f8f9fb;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 16px;
  border-radius: 5px;
  .total-block {
    text-align: center;
    .value-row {
      font-size: 16px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 6px;
    }
    .unit-row {
      font-size: 13px;
      color: #9299b8;
      text-transform: capitalize;
    }
  }
}

.table {
  width: 100%;
}
.item-row {
  border-bottom: 1px solid #f1f2f6;
  cursor: pointer;
  td {
    padding: 8px 4px 8px 4px;
    background-color: #fff;
    color: #5a5f7d;
    vertical-align: middle;
    border-color: #f0f2f5;
    &.middle {
      vertical-align: middle;
    }
  }
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 16px;
  }

  &.expand {
    td {
      border-bottom: none;
    }
    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0;
    }
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0;
    }
  }
  .user-photo-wrap {
    .user-photo {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }
  }
  .user-info {
    margin-left: 8px;
    .username {
      margin-bottom: 4px;
      margin-top: 2px;
      line-height: 14px;
      color: #272b41;
      font-weight: 500;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 120px;
    }
  }
  .value-text {
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
  }
  .unit-text {
    color: #5a5f7d;
    font-size: 9px;
    line-height: 13px;
    font-weight: 400;
    margin-bottom: 2px;
  }

  .menu-item {
    margin-bottom: 6px;
    color: #9299b8;
  }
  .expand-item i {
    color: #9299b8;
  }
}
.expand-row {
  padding: 10px 0;
  border-top: 1px solid #f1f2f6;
  td {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .action-list {
    font-size: 13px;
  }
}
.total-list {
  margin: 0;
  .total-block {
    text-align: center;
    .value-row {
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 4px;
    }
    .unit-row {
      font-size: 11px;
      line-height: 16px;
      color: #9299b8;
      text-transform: capitalize;
    }
  }
}

.user-photo-wrap {
  user-select: none;
  text-align: center;
  .user-photo {
    width: 96px;
    height: 96px;
    border-radius: 96px;
  }
}
.username {
  margin-top: 8px;
  line-height: 20px;
  color: #272b41;
  font-weight: 500;
  font-size: 16px;
}
.source-row {
  justify-content: center;
}

.blue-text {
  color: #3371ff;
}

.item-table {
  width: 100%;
  overflow: auto;
  .table {
    width: 100%;
    border-spacing: 0 16px;
  }
  .item-row {
    cursor: pointer;
    td {
      padding: 20px 5px;
      background-color: #fff;
      color: #5a5f7d;
      vertical-align: top;
      &.gold-text {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 36px;
        height: 36px;
        background: linear-gradient(180deg, #fada52 0%, #fce67d 100%);
        border-radius: 10px;
        margin-left: 25px;
      }
      &.silver-text {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 36px;
        height: 36px;
        background: linear-gradient(180deg, #d3d3d3 0%, #e2e2e2 100%);
        border-radius: 10px;
        margin-left: 25px;
      }
      &.bronze-text {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 36px;
        height: 36px;
        background: linear-gradient(180deg, #fba669 0%, #fec293 100%);
        border-radius: 10px;
        margin-left: 25px;
      }
    }
    .user-photo-wrap {
      .user-photo {
        width: 32px;
        height: 32px;
        border-radius: 64px;
      }
    }
    .user-info {
      margin-left: 16px;
      max-width: 200px;
      .username {
        margin-bottom: 10px;
        margin-top: 2px;
        line-height: 14px;
        color: #272b41;
        font-weight: 500;
        font-size: 11px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .source-row {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .action-row {
      display: flex;
      .action-title {
        color: #9299b8;
      }
      .action-value {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
  .action-list {
    padding-top: 12px;
    padding-bottom: 4px;
    .title {
      font-size: 14px;
      color: #5a5f7d;
      font-weight: 500;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-row {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      margin-bottom: 8px;
      .action-title {
        color: #9299b8;
        font-size: 12px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
  }
  .no-bordersmall {
    border-top: none;
  }
}
.text-center {
  margin: 36px 0 0 0;
}

.source-tag {
  line-height: 13px;
  color: #434870;
  font-weight: 500;
  font-size: 10px;
}

@media only screen and (max-width: 580px) {
  .source-row {
    justify-content: unset;
  }
  .text-center {
    margin: 23px 0 0 0;
  }
  .item-table {
    .item-row {
      td {
        &.gold-text,
        &.silver-text,
        &.bronze-text {
          width: 24px;
          height: 24px;
          margin-left: unset;
        }
      }
    }
  }
}
.loading-wrap {
  text-align: center;
  padding: 20px;
}
</style>
