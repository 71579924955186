<template>
  <a-card class="campaign-sentiment" title="Sentiment by Channel">
    <a-row :gutter="25">
      <a-col :xs="24" :sm="24" :md="24" class="sentimen-col">
        <a-card v-resize="hideOverflowTitle" :widget="true" class="sentiment-comparison no-body-padding">
          <div v-if="loading" class="loading-wrapper">
            <a-skeleton :loading="loading" active />
          </div>
          <div v-if="!loading && sentimentData.length <= 0" class="load-wrapper">
            <NoDataFound></NoDataFound>
          </div>
          <div v-if="!loading && sentimentData.length > 0" class="item-wrapper">
            <div v-for="(c, index) in sentimentData" :key="`${c.name}-${index}`">
              <div v-if="!c.noData" class="item-row">
                <span class="title content-text">
                  <img
                    v-if="c.name === 'facebook'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/facebook.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'twitter'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/twitter.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'instagram'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/instagram.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'pantip'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/pantip.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'youtube'"
                    class="logo-img youtube-img"
                    :src="require('@/assets/images/source-icon/svg/Youtube-Large.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'blockdit'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/blockdit.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'tiktok'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/tiktok.svg')"
                    alt="logo"
                  />
                  <img
                    v-else-if="c.name === 'website'"
                    class="logo-img"
                    :src="require('@/assets/images/source-icon/svg/website.svg')"
                    alt="logo"
                  />
                </span>
                <span class="title">
                  {{ c.name }}
                </span>
                <div class="prog-bar">
                  <span
                    v-for="sentiment in c.data"
                    :key="sentiment.name"
                    class="prog-segment has-data"
                    :class="sentiment.name"
                    :style="{ width: sentiment.percent * 100 + '%' }"
                    @click="onSegmentClick(c.name, sentiment.name)"
                  >
                    <a-tooltip placement="bottom">
                      <template #title>
                        <span class="prog-inner-element">
                          {{ sentiment.name }}: {{ sentiment.percent }}% ({{ sentiment.value }})
                        </span>
                      </template>

                      <span class="prog-inner-element"> {{ sentiment.percent }}% </span>
                    </a-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { ref, reactive, toRefs, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import numeral from 'numeral';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  name: 'PerformanceSentimentByChannel',
  components: {
    NoDataFound,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    let loading = ref(false);
    const noData = ref(false);
    let { mode, filterResult } = toRefs(props);
    const sentimentData = ref([]);
    const sentimentDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });

    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const brand = computed(() => store.state.account.brand);

    const checkSelectedSerieData = () => {
      if (sentimentData.value && sentimentData.value.length > 0) {
        noData.value = false;
      } else {
        noData.value = true;
      }
    };

    const convertPortionData = (key, obj) => {
      const result = {
        name: key,
        data: [],
      };
      if (obj.negativePercent !== 0) {
        result.data.push({
          name: 'negative',
          value: helper.numeral(obj.negative),
          percent: numeral(obj.negativePercent * 100).format('0'),
        });
      }
      if (obj.neutralPercent !== 0) {
        result.data.push({
          name: 'neutral',
          value: helper.numeral(obj.neutral),
          percent: numeral(obj.neutralPercent * 100).format('0'),
        });
      }
      if (obj.positivePercent !== 0) {
        result.data.push({
          name: 'positive',
          value: helper.numeral(obj.positive),
          percent: numeral(obj.positivePercent * 100).format('0'),
        });
      }
      return result;
    };

    const init = async (fv) => {
      loading.value = true;
      if (fv) {
        const result = await api.getCampaignSentimentSource(fv, brand.value);
        if (result.message && result.message.data) {
          // { cat: { count, engagement, view }}
          // TO count[], engage[], view[]
          const countList = [];
          const engageList = [];
          const viewList = [];
          for (let categoryKey in result.message.data) {
            const { count, engagement, view } = result.message.data[categoryKey];
            const objEngagement = convertPortionData(result.message.data[categoryKey].source, engagement);
            const objCount = convertPortionData(result.message.data[categoryKey].source, count);
            const objView = convertPortionData(result.message.data[categoryKey].source, view);
            if (objEngagement.data.length !== 0) engageList.push(objEngagement);
            if (objCount.data.length !== 0) countList.push(objCount);
            if (objView.data.length !== 0) viewList.push(objView);
          }
          sentimentDataByMode.engagement = engageList;
          sentimentDataByMode.mention = countList;
          sentimentDataByMode.view = viewList;

          sentimentData.value = sentimentDataByMode[mode.value];
          checkSelectedSerieData();

          await nextTick();
          hideOverflowTitle();
        }
      }
      loading.value = false;
    };

    const onSegmentClick = (categoryData, sentimentData) => {
      const sentiment = [sentimentData];
      let f = {
        payload: {
          title: `Data for "${helper.capitalize(categoryData)}" and "${helper.capitalize(sentimentData)}" sentiment `,
        },
        criteria: {
          sentiment,
          category: filterResult.value.category,
          subCategory: filterResult.value.subCategory,
          time: filterResult.value.time,
          source: [categoryData],
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (campaignLevel.value > 0) {
        f.criteria['subCategory'] = filterResult.value.subCategory;
      } else {
        f.criteria['category'] = filterResult.value.category;
        f.criteria['subCategory'] = [];
      }
      store.dispatch('message/showMessageModal', f);
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.prog-inner-element');

      if (allSegment) {
        allSegment.forEach((obj) => {
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };
    watch(
      () => mode.value,
      () => {
        sentimentData.value = sentimentDataByMode[mode.value];
        checkSelectedSerieData();
      }
    );

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      }
    );

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };
    return {
      sentimentData,
      sentimentDataByMode,
      onSegmentClick,
      hideOverflowTitle,
      loading,
      isMobileScreen,
      selectedModeStyle,
      unSelectedModeStyle,
      numeral,
    };
  },
};
</script>

<style lang="scss">
.table-sentiment {
  .no-text {
    font-weight: 500;
    color: #272b41;
  }

  .ant-table-without-column-header table {
    color: #5a5f7d;
  }
}
.align-right {
  text-align: right;
  padding-right: 50px;
}
.tab-engagement {
  font-size: 12px;
  color: #868eae;
}
.sentiment-comparison {
  .loading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }
  .item-wrapper {
    overflow-y: auto;
    padding-left: unset;
  }
  .item-row {
    margin: 16px 0px 16px 0px; //16px 16px 16px 32px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .title {
      padding-left: 15px;
      color: #5a5f7d;
      font-size: 14px;
      // min-width: 120px;
      flex: 0 0 120px;
      text-transform: capitalize;
      .youtube-img {
        width: 30px;
        height: 30px;
      }
    }

    .title.content-text {
      display: contents;
    }
    .title-thead {
      color: #272b41;
      font-size: 14px;
      // min-width: 120px;
      flex: 0 0 120px;
      text-transform: capitalize;
    }
    .prog-bar {
      flex: 1 1 auto;
      display: flex;
      margin-right: 5%;
      .prog-segment {
        text-align: center;
        color: #fff;
        height: 18 px;
        line-height: 18px;
        .prog-inner-element {
          font-size: 12px;
        }
      }
      .prog-column-sentiment {
        font-size: 12px;
        text-align: center;
        color: #868eae;
        height: 18 px;
        line-height: 18px;
      }
      .has-data {
        cursor: pointer;
      }
      .no-data {
        background-color: #aaa;
        width: 100%;
      }
      .positive {
        background-color: #20c997;
      }
      .neutral {
        background-color: #febc3c;
      }
      .negative {
        background-color: #ff4d4f;
      }
    }
  }
  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }
  .more-menu {
    color: #9299b8;
  }
}

@media only screen and (max-width: 823px) {
  .sentiment-comparison {
    .item-row {
      .title {
        flex: 0 0 100px;
        padding-left: 8px;
      }
      .title-thead {
        flex: 0 0 100px;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .sentiment-comparison {
    .item-row {
      .title {
        font-size: 11px !important;
      }
      .content-text {
        .logo-img {
          width: 20px;
          height: 20px;
        }
      }
      .prog-bar {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
<style scoped>
@media only screen and (max-width: 767px) {
  .sentimen-col {
    padding: 0 !important;
  }
  :deep(.campaign-sentiment.ant-card.card-height .ant-card-body) {
    padding: 0 !important;
  }
  :deep(.sentiment-comparison.ant-card.no-body-padding .ant-card-body) {
    padding: 20px !important;
  }
}
@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper) {
    height: fit-content !important;
    align-items: flex-start;
  }
}
</style>
