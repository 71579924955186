<template>
  <a-card :title="'Sentiment'" class="sentiment-wrapper">
    <template v-if="loading || (selectedData && selectedData.length > 0)">
      <div v-if="loading" class="sentiment-content-loading">
        <a-skeleton :loading="loading" active />
      </div>

      <div v-else class="sentiment-content">
        <div class="doughnut-chart-content">
          <EChart
            ref="sentimentEchart"
            :type="'pie'"
            :data="selectedData"
            :doughnut-chart="true"
            chart-type="sentiment"
            @chartClick="onChartClick"
          />
        </div>

        <div class="flex-center-center">
          <div class="sentiment-block" @click="onLegendClick('positive')">
            <div class="title">
              <a-badge :color="selectedSentiment['positive'] ? '#cccccc' : '#20C997'" />
              Positive
            </div>
            <div class="val" :class="{ 'color-muted': selectedSentiment['positive'] }">
              {{ mapData.Positive }}
            </div>
          </div>
          <div class="sentiment-block" @click="onLegendClick('neutral')">
            <div class="title">
              <a-badge :color="selectedSentiment['neutral'] ? '#cccccc' : '#febc3c'" />
              Neutral
            </div>
            <div class="val" :class="{ 'color-muted': selectedSentiment['neutral'] }">
              {{ mapData.Neutral }}
            </div>
          </div>
          <div class="sentiment-block" @click="onLegendClick('negative')">
            <div class="title">
              <a-badge :color="selectedSentiment['negative'] ? '#cccccc' : '#FF4D4F'" />
              Negative
            </div>
            <div class="val" :class="{ 'color-muted': selectedSentiment['negative'] }">
              {{ mapData.Negative }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="sentiment-no-data">
      <NoDataFound />
    </div>
  </a-card>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, toRefs, watch, reactive } from 'vue';
import api from '@/services/api';
import helper from '@/services/helper';
import numeral from 'numeral';
import EChart from '@/components/Chart/Echart.vue';
import chartHelper from '@/services/chartHelper.js';
import NoDataFound from '@/components/Error/NoDataFound.vue';

const Sentiment = {
  name: 'PerformanceSentiment',
  components: {
    EChart,
    NoDataFound,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup(props) {
    let { mode, filterResult } = toRefs(props);
    const store = useStore();
    // const criteria = computed(() => filter.value);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const loading = ref(true);
    const sentimentData = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    let selectedSentiment = ref({
      positive: false,
      neutral: false,
      negative: false,
    });

    const qs = {
      'chart-type': 'pie',
      level: 0,
    };

    const selectedData = ref([]);

    const mapData = computed(() => {
      const result = {};
      if (selectedData.value) {
        selectedData.value.forEach((obj) => {
          result[obj.name] = helper.numeral(obj.value, '0,0');
        });
      }
      return result;
    });

    const getSentiment = async (criteria, qs) => {
      let result = await api.getSentimentPie(criteria, qs).catch(() => {});
      let engagementDataObjResponse = null;
      let countDataObjResponse = null;
      let viewDataObjResponse = null;

      if (
        result &&
        result.message != null &&
        result.message.graphData != null &&
        result.message.graphData.engagement != null &&
        result.message.graphData.count != null &&
        result.message.graphData.view != null
      ) {
        engagementDataObjResponse = result.message.graphData.engagement;
        countDataObjResponse = result.message.graphData.count;
        viewDataObjResponse = result.message.graphData.view;
      }

      let engagementResult = [];
      if (engagementDataObjResponse !== null) {
        let total = 0;
        let positive = engagementDataObjResponse.positive || 0;
        let negative = engagementDataObjResponse.negative || 0;
        let neutral = engagementDataObjResponse.neutral || 0;
        total = positive + negative + neutral;
        if (total > 0) {
          for (const [index, [key, value]] of Object.entries(Object.entries(engagementDataObjResponse))) {
            const color = chartHelper.getColor(key.toLowerCase(), index);
            let valueAsPercent = convertValueToPercent(value, total);
            engagementResult.push({
              name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
              value: value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
          }
          sentimentData.engagement = engagementResult.sort((a, b) =>
            a.value > b.value ? -1 : b.value > a.value ? 1 : 0
          );
        } else {
          sentimentData.engagement = [];
        }
      }

      let countResult = [];
      if (countDataObjResponse !== null) {
        let total = 0;
        let positive = countDataObjResponse.positive || 0;
        let negative = countDataObjResponse.negative || 0;
        let neutral = countDataObjResponse.neutral || 0;
        total = positive + negative + neutral;
        if (total > 0) {
          for (const [index, [key, value]] of Object.entries(Object.entries(countDataObjResponse))) {
            const color = chartHelper.getColor(key.toLowerCase(), index);
            let valueAsPercent = convertValueToPercent(value, total);
            countResult.push({
              name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
              value: value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
          }
          sentimentData.mention = countResult.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
        } else {
          sentimentData.mention = [];
        }
      }

      let viewResult = [];
      if (viewDataObjResponse !== null) {
        let total = 0;
        let positive = viewDataObjResponse.positive || 0;
        let negative = viewDataObjResponse.negative || 0;
        let neutral = viewDataObjResponse.neutral || 0;
        total = positive + negative + neutral;
        if (total > 0) {
          for (const [index, [key, value]] of Object.entries(Object.entries(viewDataObjResponse))) {
            const color = chartHelper.getColor(key.toLowerCase(), index);
            let valueAsPercent = convertValueToPercent(value, total);
            viewResult.push({
              name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
              value: value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
          }
          sentimentData.view = viewResult.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
        } else {
          sentimentData.view = [];
        }
      }

      selectedData.value = sentimentData[mode.value];
    };

    const convertValueToPercent = (value, total) => {
      return numeral((value / total) * 100).format('0.00');
    };

    const onChartClick = (params) => {
      const sentiment = params.name.toLowerCase();
      let f = {
        payload: {
          title: `${helper.capitalize(params.name)} sentiment detail`,
        },
        criteria: {
          category: filterResult.value.category,
          subCategory: filterResult.value.subCategory,
          sentiment: [sentiment],
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };

      if (campaignLevel.value > 0) {
        const category = filterResult.value.subCategory;
        for (let i in f.criteria['subCategory']) {
          if (f.criteria['subCategory'][i].level == campaignLevel.value) {
            f.criteria['subCategory'][i] = category[0];
          }
        }
      } else {
        f.criteria['category'] = filterResult.value.category;
        f.criteria['subCategory'] = [];
      }

      store.dispatch('message/showMessageModal', f);
    };

    const sentimentEchart = ref(null);

    const onLegendClick = (sentiment) => {
      let item;
      for (let data of selectedData.value) {
        if (sentiment === data.name.toLowerCase()) {
          item = data;
        }
      }
      if (selectedSentiment.value[sentiment]) {
        selectedSentiment.value[sentiment] = false;
      } else {
        selectedSentiment.value[sentiment] = true;
      }
      sentimentEchart.value.eChart.dispatchAction({
        type: 'legendToggleSelect',
        // legend name
        name: item.name,
      });
    };

    const init = async (fv) => {
      loading.value = true;
      await getSentiment(fv, qs);
      loading.value = false;
    };

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      }
    );

    watch(
      () => mode.value,
      () => {
        if (mode.value) {
          selectedData.value = sentimentData[mode.value];
        }
      }
    );

    return {
      loading,
      mapData,
      selectedData,
      onChartClick,
      onLegendClick,
      selectedSentiment,
      sentimentEchart,
    };
  },
};

export default Sentiment;
</script>

<style lang="scss" scoped>
.sentiment-wrapper {
  .sentiment-content-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sentiment-content {
    .doughnut-chart-content {
      height: 300px;
      display: flex;
      align-items: center;
      margin-bottom: 35px;
    }
    .sentiment-block {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;
      .title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: #868eae;
        margin-bottom: 4px;
      }
      .val {
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #272b41;
      }
      .color-muted {
        color: #ccc;
      }
    }
  }

  .sentiment-no-data {
    height: 335px;
  }
}
</style>
<style scoped>
@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper) {
    height: fit-content !important;
    align-items: flex-start;
  }
}
</style>
