<template>
  <div>
    <table v-if="influencerList.length > 0" class="table">
      <tbody>
        <tr
          v-for="(item, index) in influencerList"
          :key="item.id"
          class="item-row"
          @click="showMessageModal(item.id, item.info.user_name)"
        >
          <td v-if="index <= 2" class="text-center first">
            <img
              v-if="index === 0"
              class="medal-img"
              :src="require('@/assets/images/icon/01-medal-gold.svg')"
            />
            <img
              v-if="index === 1"
              class="medal-img"
              :src="require('@/assets/images/icon/02-medal-silver.svg')"
            />
            <img
              v-if="index === 2"
              class="medal-img"
              :src="require('@/assets/images/icon/03-medal-bronze.svg')"
            />
          </td>
          <td v-else class="text-center first">
            {{ index + 1 }}
          </td>
          <td class="influencer-td">
            <div class="d-flex">
              <div class="user-photo-wrap">
                <img
                  class="user-photo"
                  :src="item.newProfileImage"
                  @error="onImageError($event, item)"
                />
                <div>
                  <SourceTag :source="item.source" :showLabel="false" />
                </div>
              </div>
              <div class="user-info">
                <div class="username">
                  {{ item.info.user_name }}
                </div>
                <a class="user-tag" :href="item.info.user_link" target="_blank"
                  >@{{ item.info.user_name }}</a
                >
                <!-- <div
                  v-if="
                    typeof item.engagement.follower === 'string' &&
                    typeof item.engagement.rate === 'string' &&
                    item.source === 'twitter'
                  "
                  class="horizontal-list rate-row"
                >
                  <div v-if="typeof item.engagement.follower === 'string'" class="action-row">
                    <div class="action-title">
                      <FeatherIcon :type="'users'" size="12" />
                    </div>
                    <div class="action-value">
                      {{ item.engagement.follower || 0 }}
                    </div>
                  </div>
                  <div class="action-row">
                    <div v-if="typeof item.engagement.rate === 'string'" class="action-title">
                      <FeatherIcon :type="'pie-chart'" size="12" />
                    </div>
                    <div class="action-value">
                      {{ item.engagement.rate || 0 }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </td>
          <td class="influencer-td">
            <div class="total-text">Engagement</div>
            <div
              class="total-value"
              :class="{ 'blue-text': mode === 'engagement' }"
            >
              {{ item.totalEngage }}
            </div>
            <div class="horizontal-list">
              <div
                v-if="typeof item.engagement.reaction === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'thumbs-up'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.reaction || 0 }}
                </div>
              </div>
              <div
                v-if="typeof item.engagement.like === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon
                    v-if="
                      item.source === 'twitter' || item.source === 'twitterv2'
                    "
                    :type="'heart'"
                    size="12"
                  />
                  <FeatherIcon v-else :type="'thumbs-up'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.like || 0 }}
                </div>
              </div>
              <div
                v-if="typeof item.engagement.comment === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'message-square'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.comment || 0 }}
                </div>
              </div>
              <div
                v-if="typeof item.engagement.share === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'share-2'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.share || 0 }}
                </div>
              </div>
              <div
                v-if="typeof item.engagement.retweet === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'repeat'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.retweet || 0 }}
                </div>
              </div>
              <div
                v-if="typeof item.engagement.quote === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'edit-3'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.engagement.quote || 0 }}
                </div>
              </div>
            </div>
          </td>
          <td class="influencer-td">
            <div class="total-text">Mentioned</div>
            <div
              class="total-value-mention"
              :class="{ 'blue-text': sortBy === 'count' }"
            >
              {{ item.totalMention }}
            </div>
            <div class="horizontal-list">
              <div
                v-if="typeof item.mention.post === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon :type="'file-text'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.mention.post }}
                </div>
              </div>
              <div
                v-if="typeof item.mention.tweet === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <font-awesome-icon :icon="faXTwitter" />
                </div>
                <div class="action-value">
                  {{ item.mention.tweet }}
                </div>
              </div>
              <div
                v-if="typeof item.mention.comment === 'string'"
                class="action-row"
              >
                <div class="action-title">
                  <FeatherIcon
                    v-if="
                      item.source === 'twitter' || item.source === 'twitterv2'
                    "
                    :type="'repeat'"
                    size="12"
                  />
                  <FeatherIcon v-else :type="'message-square'" size="12" />
                </div>
                <div class="action-value">
                  {{ item.mention.comment || 0 }}
                </div>
              </div>
            </div>
          </td>
          <td class="influencer-td">
            <div class="total-text">Sentiment</div>
            <div class="sentiment-value">
              <div class="sentiment-circle positive">
                <FeatherIcon :type="'smile'" :size="12" />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.positive === 0 }"
              >
                {{ item.sentiment.positive }}
              </div>
            </div>
            <div class="sentiment-value">
              <div class="sentiment-circle neutral">
                <FeatherIcon :type="'meh'" :size="12" />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.neutral === 0 }"
              >
                {{ item.sentiment.neutral }}
              </div>
            </div>
            <div class="sentiment-value">
              <div class="sentiment-circle negative">
                <FeatherIcon :type="'frown'" :size="12" />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.negative === 0 }"
              >
                {{ item.sentiment.negative }}
              </div>
            </div>
          </td>
          <td class="influencer-td">
            <div class="total-text">Views</div>
            <div class="horizontal-list">
              <div class="action-row">
                <div class="action-title">
                  <FeatherIcon :type="'eye'" size="14" />
                </div>
                <div class="action-value">
                  {{ item.engagement.view || 0 }}
                </div>
              </div>
            </div>
            <!-- <div v-if="item.source === 'twitter'" class="horizontal-list">
              <div class="action-row">
                <div class="action-title">
                  <FeatherIcon :type="'bar-chart'" size="14" />
                </div>
                <div class="action-value">
                  {{ item.engagement.view_count || 0 }}
                </div>
              </div>
            </div> -->
          </td>
          <td>
            <a-dropdown
              class="user-menu-wrap"
              :placement="'bottomRight'"
              trigger="click"
            >
              <div
                class="user-menu-button"
                @click.stop="clickMenu(`dropdown-table-${index}`)"
              >
                <FeatherIcon type="more-horizontal" size="24" />
              </div>
              <template #overlay>
                <a-menu
                  :id="`dropdown-table-${index}`"
                  class="user-influencer-menu"
                >
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="openUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="external-link" />
                      Open Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="copyUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="copy" />
                      Copy Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showMessageModal(item.id, item.info.user_name)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="eye" />
                      View Message
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showNetworkGraph(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="share-2" />
                      Network Graph
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="load-wrapper">
      <NoDataFound></NoDataFound>
    </div>
    <!-- <SeeMoreDialog /> -->
    <a-modal
      v-model:visible="isNetworkModalOpen"
      :title="networkData.title"
      :footer="null"
      :on-cancel="onNetworkModalCancel"
      width="838px"
      :z-index="1000"
    >
      <div v-if="networkData.loading" class="loading-wrap">
        <a-spin />
      </div>
      <div
        v-show="!networkData.loading"
        id="influencer-graph"
        ref="infGraph"
      ></div>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, provide, toRefs } from 'vue';
import { useStore } from 'vuex';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import helper from '@/services/helper';
import api from '@/services/api';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SourceTag from '@/components/SourceTag.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
const networkGraphOption = {
  autoResize: true,
  height: '500px',
  width: '100%',
  interaction: {
    hover: true,
    navigationButtons: true,
    keyboard: {
      enabled: true,
      bindToWindow: false,
    },
  },
  edges: {
    smooth: {
      forceDirection: 'none',
      roundness: 0.4,
    },
    scaling: {
      min: 2,
      max: 10,
    },
  },
  nodes: {
    color: {
      highlight: {
        border: '#ff4d4d',
        background: '#ff4d4d',
      },
      hover: {
        border: '#99ffbb',
        background: '#99ffbb',
      },
    },
    scaling: {
      min: 25,
      max: 35,
      label: {
        min: 15,
        max: 18,
      },
    },
  },
  physics: {
    enabled: true,
    forceAtlas2Based: {
      gravitationalConstant: -50,
      centralGravity: 0.01,
      springConstant: 0.1,
      springLength: 80,
      damping: 0.5,
      avoidOverlap: 0.8,
    },
    minVelocity: 2,
  },
};
export default {
  name: 'InfluencerTable',
  components: {
    // SeeMoreDialog,
    SourceTag,
    NoDataFound,
  },
  props: {
    influencerList: Array,
    filterResult: Object,
    sortBy: String,
    campaign: Object,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, campaign } = toRefs(props);
    const limitCount = ref(10);
    const error = ref(false);
    const isNetworkModalOpen = ref(false);
    const infGraph = ref();
    const expandRowId = ref('');
    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };
    const networkData = reactive({
      nodes: new DataSet(),
      edges: new DataSet(),
      loading: false,
      title: '',
      allParent: [],
      networkParent: null,
      edgeLimit: 50,
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      if (campaign.value.name) {
        if (campaign.value.level > 0) {
          const category = filterResult.value.subCategory;
          for (let i in f.criteria['subCategory']) {
            if (f.criteria['subCategory'][i].level == campaign.value.level) {
              f.criteria['subCategory'][i] = category[0];
            }
          }
        } else {
          f.criteria['category'] = filterResult.value.category;
          f.criteria['subCategory'] = [];
        }
      }
      store.dispatch('message/showMessageModal', f);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const htmlTitle = (html) => {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    };

    const setParent = (parent) => {
      networkData.title = `Network graph of "${parent.info.user_name || '-'}"`;
      let actions = parent.actions;
      let reactions = [];
      Object.keys(parent.engagement).forEach((engageKey) => {
        reactions.push({
          key: engageKey,
          value: parent.engagement[engageKey],
        });
      });

      let title =
        '' +
        '<div class="name">' +
        parent.info.user_name +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        helper.numeral(parent.node_score, '0,0', false, null) +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';

      const img = parent.img || parent.newProfileImage;
      networkData.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.info.user_name,
        shape: 'circularImage',
        image: img,
        title: htmlTitle(title),
        value: 2,
      });
    };
    const setChild = async (parent) => {
      const networkArg = {
        ...filterResult.value,
        nodeLimit: 50,
        edgeLimit: networkData.edgeLimit,
      };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(true, parentID, networkArg)
        .catch(() => {
          console.log('ERRR');
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image: childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      networkData.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = networkData.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = networkData.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      networkData.edges.update(childlist.relationship);
      networkData.loading = false;
    };

    const showNetworkGraph = async (parent) => {
      isNetworkModalOpen.value = true;
      networkData.loading = true;
      networkData.allParent = [];
      networkData.networkParent = parent;
      networkData.nodes.clear();
      networkData.edges.clear();
      setParent(parent);
      await setChild(parent);
      // console.log(infGraph.value, networkData.nodes, networkData.edges, Network, networkGraphOption);

      new Network(
        infGraph.value,
        { nodes: networkData.nodes, edges: networkData.edges },
        networkGraphOption
      );
    };
    const onNetworkModalCancel = () => {
      console.log('OHH');
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        window.open(item.info.user_link, '_blank');
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        navigator.clipboard.writeText(item.info.user_link);
      }
    };

    const onImageError = (e, value) => {
      if (value.source === 'facebook') {
        if (value.fallbackImage && e.target.src !== value.fallbackImage) {
          e.target.src = value.fallbackImage;
        } else {
          const img = helper.getDefaultImageProfile(value.source);
          e.target.src = img;
        }
      } else {
        const img = helper.getDefaultImageProfile(value.source);
        e.target.src = img;
      }
    };

    provide('showMode', '');

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName(
        'user-influencer-menu'
      );
      if (scrollElement) {
        for (let ele of scrollElement) {
          // ele.remove();
          ele.style.display = 'none';
        }
      }
    };

    return {
      error,
      clickMenu,
      openUserLink,
      onNetworkModalCancel,
      copyUserLink,
      showNetworkGraph,
      onImageError,
      showMessageModal,
      onScroll,
      toggleExpandRow,
      expandRowId,
      limitCount,
      infGraph,
      isNetworkModalOpen,
      networkData,
      faXTwitter,
    };
  },
};
</script>

<style lang="scss" scoped>
.blue-text {
  color: #3371ff;
}

.text-center {
  margin: 36px 0 0 0;
}

.table {
  width: 100%;
  border-spacing: 0 16px;
}
.item-row {
  border-bottom: 1px solid #f1f2f6;
  cursor: pointer;
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031);
  td {
    padding: 20px 8px;
    background-color: #fff;
    color: #5a5f7d;
    vertical-align: top;
    &.gold-text {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 36px;
      height: 36px;
      background: linear-gradient(180deg, #fada52 0%, #fce67d 100%);
      border-radius: 10px;
      margin-left: 25px;
    }
    &.silver-text {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 36px;
      height: 36px;
      background: linear-gradient(180deg, #d3d3d3 0%, #e2e2e2 100%);
      border-radius: 10px;
      margin-left: 25px;
    }
    &.bronze-text {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 36px;
      height: 36px;
      background: linear-gradient(180deg, #fba669 0%, #fec293 100%);
      border-radius: 10px;
      margin-left: 25px;
    }
    &.first {
      vertical-align: middle;
    }
  }
  .medal-img {
    width: 40px;
    height: 50px;
  }
  .influencer-td {
    padding: 20px 36px;
    // border-right: 1px solid #f1f2f6;
    .user-photo-wrap {
      position: relative;
      .user-photo {
        width: 64px;
        height: 64px;
        border-radius: 64px;
      }
      .source-tag {
        position: absolute;
        right: 3px;
        left: auto;
        bottom: 3px;
      }
    }
    .user-info {
      margin-left: 16px;
      max-width: 200px;
      .username {
        margin-bottom: 4px;
        margin-top: 2px;
        line-height: 18px;
        color: #272b41;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .source-row {
      margin-top: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .rate-row {
      margin-top: 20px;
    }
    .user-tag {
      color: #9299b8;
      font-size: 13px;
    }
    .total-text {
      color: #5a5f7d;
      line-height: 16px;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .total-value {
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .total-value-mention {
      color: #272b41;
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .horizontal-list {
      display: flex;
      column-gap: 16px;
      .action-row {
        display: flex;
        .action-title {
          color: #9299b8;
        }
        .action-value {
          font-size: 12px;
          margin-left: 8px;
        }
      }
    }
    .sentiment-block,
    .sentiment-value {
      display: flex;
      align-items: center;
      .sentiment-circle {
        display: flex;
        padding: 2px;
        border-radius: 24px;
        height: 16px;
        width: 16px;
        overflow: hidden;
        text-align: center;
        margin-right: 4px;
        &.positive {
          background-color: rgba(32, 201, 151, 0.15);
          color: #20c997;
        }
        &.neutral {
          background-color: rgba(250, 139, 12, 0.15);
          color: #fa8b0c;
        }
        &.negative {
          background-color: rgba(255, 77, 79, 0.15);
          color: #ff4d4f;
        }
        .value {
          font-size: 14px;
          color: #5a5f7d;
          &.zero {
            color: #adbcd2;
          }
        }
      }
    }
  }
  .views-td {
    padding: 20px 0 20px 36px;
    .horizontal-list {
      display: flex;
      column-gap: 16px;
      .action-row {
        display: flex;
        .action-title {
          color: #9299b8;
        }
        .action-value {
          font-size: 12px;
          margin-left: 8px;
        }
      }
    }
  }
  .user-menu-wrap {
    display: flex;
    align-items: center;
    padding: 16px;
    color: #adb4d2;
  }
  .action-list {
    .action-row {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      margin-bottom: 8px;
      .action-title {
        color: #9299b8;
        font-size: 14px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
    &.mobile {
      .title,
      .action-row .action-title {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .post-card {
    .text-center {
      margin: 23px 0 0 0;
    }
    .item-table {
      .item-row {
        td {
          &.gold-text,
          &.silver-text,
          &.bronze-text {
            width: 30px;
            height: 30px;
            margin-left: unset;
          }
        }
      }
    }
  }
}
.loading-wrap {
  text-align: center;
  padding: 20px;
}
</style>
